import React from 'react'
import { Carousel } from 'react-bootstrap'
import Slide1 from '../../../../image/slide1.png'
import Slide2 from '../../../../image/slide2.png'

function Slider() {
    return <>
        <Carousel variant="dark">

            <Carousel.Item>

                <img
                    className="d-block w-100"
                    src={Slide1}
                    alt="First slide"
                />
                {/* <Carousel.Caption>
                    <h5>First slide label</h5>
                    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption> */}
            </Carousel.Item>

          {/*   <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Slide2}
                    alt="Second slide"
                />
                <Carousel.Caption>
                    <h5>Second slide label</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </Carousel.Caption> 
            </Carousel.Item>*/}

        </Carousel>
    </>
}
export { Slider }