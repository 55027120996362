import React, { useState } from 'react';
import { Card, Button, Col, Modal } from 'react-bootstrap';
import imageCottages from '../../../../image/Cottages.jpg'

import { Booking } from '../../utils';
function Cottages() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (<><Col>
        <div className="uBlock" style={{ position: 'relative', backgroundImage: `url(${imageCottages})`, backgroundSize: 'cover' }}>
            <div style={{ margin: '10px', color: 'white', fontSize: 30, fontFamily: 'cursive', textShadow: '#000 1px 0 2px', }}>
                Коттеджи
            </div>
            <div style={{ margin: '10px', color: 'white', fontSize: 18, fontFamily: 'cursive', textShadow: '#000 1px 0 2px', }}>
                В наших коттеджах Вы сможете почувствовать себя максимально уютно и комфортно
            </div>
            <div style={{ position: 'absolute', bottom: 0 }}>
                <Button className='myButton' style={{ width: 100 }}>
                    Обзор
                </Button>
                <Button className='myButton' style={{ width: 150 }} onClick={handleShow}>
                    Бронирование
                </Button>
            </div>
        </div>
    </Col>
        <Modal show={show} onHide={handleClose}>
            <Booking button={<Button variant="primary" onClick={handleClose} >
                Бронировать
            </Button>} />
        </Modal>
    </>
    )
}

export { Cottages }