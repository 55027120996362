import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import Logo from '../../../../image/logo.png'

function NavBar() {
    return <Navbar bg="dark" variant="dark">
        <Container>
            <img
                alt=""
                src={Logo}
                width="150"
                height="56"
                className="d-inline-block align-top "
                style={{ margin: '-1px' }}
            />
        </Container>
    </Navbar>
}

export { NavBar }