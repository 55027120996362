import React, { useState } from 'react';
import { Row, Button, Col, Modal, Container } from 'react-bootstrap';
import catamaran from '../../../../image/Additionally/catamaran.png'
import kayak from '../../../../image/Additionally/kayak.png'
import deckСhair from '../../../../image/Additionally/deckСhair.png'
import coctail from '../../../../image/Additionally/coctail.png'
import food from '../../../../image/Additionally/food.jpg'
import barbeq from '../../../../image/Additionally/barbeq.png'
import fishing from '../../../../image/Additionally/fishing.png'
import swing from '../../../../image/Additionally/swing.png'
import './Additionally.css'

function Additionally() {
    return (<>
        <Container className='mt-3'>
            <Col className='element' >
                <img className='image' src={catamaran} />
                <div className='text'>
                    Катамараны
                </div>
            </Col>
            <Col className='element' >
                <img className='image' src={kayak} />
                <div className='text'>
                    Каяки
                </div>
            </Col>
            <Col className='element'>
                <img className='image' src={food} />
                <div className='text'>
                    Кухня
                </div>
            </Col>
            <Col className='element' >
                <img className='image' src={deckСhair} />
                <div className='text'>
                    Шезлонги
                </div>
            </Col>
            <Col className='element'>
                <img className='image' src={coctail} />
                <div className='text'>
                    Коктейли
                </div>
            </Col>
            <Col className='element'>
                <img className='image' src={swing} />
                <div className='text'>
                    Качели
                </div>
            </Col>
            <Col className='element'>
                <img className='image' src={barbeq} />
                <div className='text'>
                    Мангальная зона
                </div>
            </Col>
            <Col className='element'>
                <img className='image' src={fishing} />
                <div className='text'>
                    Рыбалка
                </div>
            </Col>


        </Container>

    </>)
}


export { Additionally }