import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NavBar, Slider } from './Header';
import { Additionally, Cottages, Gazebos, Main, Territory, Uslugi } from './Main';

export function IndexHome() {
    return <>

        <NavBar />
        <Slider />

        <Container >
            <Main />
            {/* <Territory /> */}
            <Row xs={1} md={2} className="g-4">
                <Col>
                    <Gazebos />
                </Col>
                <Col>
                    <Cottages />
                </Col>
            </Row>
        </Container>
        <Additionally />
        <Uslugi />
    </>
}