import React, { useState } from 'react';
import { Card, Button, Col, Modal } from 'react-bootstrap';
import imageGazebos from '../../../../image/Gazebos.jpg';
import { Booking } from '../../utils';
import './Button.css'
function Gazebos() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (<><Col>
        <div className="uBlock" style={{
            position: 'relative',
            backgroundImage: `url(${imageGazebos})`,
            backgroundSize: 'cover',
        }}>
            <div style={{}}>
                <div style={{ margin: '10px', color: 'white', fontSize: 30, fontFamily: 'cursive', textShadow: '#000 1px 0 2px', }}>
                    Беседки
                </div>
                <div style={{ margin: '10px', color: 'white', fontSize: 18, fontFamily: 'cursive', textShadow: '#000 1px 0 2px', }}>
                    Уютные беседки для отдыха компанией
                </div>
            </div>
            <div style={{ position: 'absolute', bottom: 0 }}>
                <Button className='myButton' style={{ width: 100 }}>
                    Обзор
                </Button>
                <Button className='myButton' style={{ width: 150 }} onClick={handleShow}>
                    Бронирование
                </Button>
            </div>
        </div>
    </Col >
        <Modal show={show} onHide={handleClose}>
            <Booking button={<Button variant="primary" onClick={handleClose}>
                Бронировать
            </Button>} />
        </Modal>
    </>)
}

export { Gazebos }