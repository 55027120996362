import React from 'react';
import iconLake from '../../../../image/icons/iconLake.svg';
import iconWifi from '../../../../image/icons/iconWifi.svg';
import iconService from '../../../../image/icons/iconService.svg';
import iconParking from '../../../../image/icons/iconParking.svg';
import { Row, Col, Container } from 'react-bootstrap';
import './Uslugi.css';

function Uslugi() {
    return (<>
        <Container  >
            <Row className="center">
                <Col xl={3} sm={6}>
                    <div className="item ">
                        <img src={iconService} />
                        <p>Круглосуточный сервис и охрана</p>
                    </div>
                </Col>
                <Col xl={3} sm={6}>
                    <div className="item">
                        <img src={iconParking} />
                        <p>Большой паркинг под видеоконтролем</p>
                    </div>
                </Col>
                <Col xl={3} sm={6}>
                    <div className="item">
                        <img src={iconLake} />
                        <p>Озеро и возможность рыбалки</p>
                    </div>
                </Col>
                <Col xl={3} sm={6}>
                    <div className="item">
                        <img src={iconWifi} />
                        <p>Бесплатный WiFi на территории комплекса</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </>)
}

export { Uslugi }