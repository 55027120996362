import React from "react"
import './Main.css'
import { Container, Col, Row } from 'react-bootstrap'

function Main() {

    return (<>

    </>)
}


export { Main }