import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { IndexHome } from '../src/Components/FrontWeb/Home/IndexHome';
function App() {
  return (
    <>
      <IndexHome />
    </>
  );
}

export default App;
