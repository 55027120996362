import { Row, Form, Col } from "react-bootstrap";
import React, { useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './Booking.css'
import ru from 'date-fns/locale/ru'; // the locale you want
registerLocale("ru", ru);
function Booking(props) {

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    return (<>
        <Form style={{ padding: '15px' }} >
            <Row className="mb-2">
                <Form.Group as={Col} controlId="formBasicEmail">
                    <Form.Label>Дата заезда</Form.Label>

                    <DatePicker locale="ru" selected={startDate} dateFormat="dd.MM.yyyy" onChange={(date: Date) => setStartDate(date)} />
                </Form.Group>
                <Form.Group as={Col} controlId="formBasicEmail">
                    <Form.Label>Дата выезда</Form.Label>
                    <DatePicker locale="ru" selected={endDate} dateFormat="dd.MM.yyyy" onChange={(date) => setEndDate(date)} selectsEnd startDate={startDate} endDate={endDate} minDate={startDate} />
                </Form.Group>
            </Row>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Фамилия Имя</Form.Label>
                <Form.Control type="text" placeholder="Фамилия Имя" />
                {/* <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                </Form.Text> */}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Номер телефона</Form.Label>
                <Form.Control type="text" placeholder="+380" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Даю согласие на обработку персональных данных" />
            </Form.Group>
            {props.button}
        </Form>

    </>)

}

export { Booking }